// components/Header.js
import { React, useEffect, useContext, useCallback } from 'react';
import Web3 from 'web3';
import ContractABI from '../ABI/StrboonABI.json';
import TokenABI from '../ABI/TokenABI.json';
import StakingABI from '../ABI/StakingABI.json';
import DataLibraryABI from '../ABI/DataLibraryABI.json';
import MerchantABI from '../ABI/MerchantABI.json';
import { WalletContext } from '../WalletContext';
import '../styles/Header.css';
import logo from '../assets/img/logo/logo.png';

const Header = () => {
  const {
    referee, setReferee, setRemainingDays,
    account, setAccount, dataLibraryAddress,
    isConnected, setIsConnected, setPendingReward, setErninngData,
    web3, setWeb3, contract, setContract,
    setApproved, connectedChainId, setDataLibraryContract,
    strboonAddress, setStrboonAddress, setIsSeller,
    tokenAddress, setTokenAddress, setUsdtDecimal,
    stakingAddress, setStakingAddress, setMerchantContract,
    stakingContract, setStakingContract, merchantAddress, merchantContract, setMerchantAddress,
    usdtContract, setUsdtContract, setMilestonRewards,
    totalRefferals, setTotalRefferals, setUserLavels,
    setThisMonthEarning, setTodaysEarning, totalEarning, setTotalEarning,
    setThisYearEarning, dataLibraryContract, setDataLibraryAddress,
    setTotalTokenStaked, setTotalInvest, setReferalIncome
  } = useContext(WalletContext);

  const masterContractAddress = '0xAC56a289fF7fc735F218Ab40F94691Ba63cEc5aD';
  const dataLibraryContractAddress = '0xf1dCeDA1E15265CB4d3BE2164fE656f4dc405576';
  const tokenContractAddress = '0x0484367a0b79C2135aBBDEdC3acf1cB728ec7731';
  const stakingContractAddress = '0xbB9AaDfbcEFB4F98a368A503395AD335DF7C89bf';
  const merchantContractAddress = '0xf2a162e302f0f0c33d840bc0de9CF2daF37C00d1';

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    } else if (window.web3) {
      const web3Instance = new Web3(window.web3.currentProvider);
      setWeb3(web3Instance);
    } else {
      console.error('No Web3 provider detected');
    }
    setStrboonAddress(masterContractAddress);
    setTokenAddress(tokenContractAddress);
    setStakingAddress(stakingContractAddress);
    setDataLibraryAddress(dataLibraryContractAddress);
    setMerchantAddress(merchantContractAddress);
  }, [setWeb3, setStrboonAddress, setTokenAddress, setStakingAddress, setDataLibraryAddress, setMerchantAddress]);

  useEffect(() => {
    const pathParts = window.location.pathname.split('/');
    const referralIndex = pathParts.indexOf('referral');

    if (referralIndex !== -1 && pathParts.length > referralIndex + 1) {
      const referralAddress = pathParts[referralIndex + 1];
      setReferee(referralAddress);
      console.log(`Referred by:`, referralAddress);
    } else {
      setReferee('0xeE2446E45a9AA2ba8979a4406F521f2C5Cf7b550');
    }
    console.log("Referraed by:", referee);

    // async function checkAndSwitchNetwork() {
    //   if (connectedChainId !== 137) {
    //     // Chain is not Polygon Mainnet, switch the network
    //     await switchToPolygonMainnet();
    //   }
    // }
    // checkAndSwitchNetwork();
  }, [setReferee]);

  async function switchToPolygonMainnet() {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }], // Polygon Mainnet chainId in hexadecimal
      });
    } catch (error) {
      if (error.code === 4902) {
        // If the chain is not added, add it
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x89',
              chainName: 'Polygon Mainnet',
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
              },
              rpcUrls: ['https://polygon-rpc.com/'],
              blockExplorerUrls: ['https://polygonscan.com/'],
            },
          ],
        });
      } else {
        console.error('Error switching to Polygon Mainnet:', error);
      }
    }
  }

  useEffect(() => {
    if (web3 && strboonAddress && tokenAddress && stakingAddress && account && dataLibraryAddress) {
      const contractInstance = new web3.eth.Contract(ContractABI, strboonAddress);
      setContract(contractInstance);
      const stakingContractInstance = new web3.eth.Contract(StakingABI, stakingAddress);
      setStakingContract(stakingContractInstance);
      const usdtContractInstance = new web3.eth.Contract(TokenABI, tokenAddress);
      setUsdtContract(usdtContractInstance);
      const dataLibraryContractInstance = new web3.eth.Contract(DataLibraryABI, dataLibraryAddress);
      setDataLibraryContract(dataLibraryContractInstance);
      const merchantContractInstance = new web3.eth.Contract(MerchantABI, merchantAddress);
      setMerchantContract(merchantContractInstance);
    }
  }, [web3, strboonAddress, tokenAddress, stakingAddress, setContract, setStakingContract, setUsdtContract, setDataLibraryContract, setMerchantContract]);

  const updateDecimals = useCallback(async () => {
    if (!isConnected || !usdtContract) {
      console.log('User not connected.');
      return;
    }
    try {
      console.log('Updating decimal.');
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      setUsdtDecimal(decimalNumber);
    } catch (error) {
      console.error('Error updating usdt decimals', error);
    }
  }, [isConnected, usdtContract, setUsdtDecimal]);

  const updateReferral = useCallback(async () => {
    if (!contract || !account) {
      console.log('Contract or account not available');
      return;
    }
    try {
      console.log("Updating referrals...");
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      const _totalRefferals = await contract.methods.getReferrerLenth(account).call();
      const _todysEarning = await contract.methods.getTodaysEarning(account).call();
      const thisMonth = await contract.methods.getThisMonthEarning(account).call();
      const thisYear = await contract.methods.getThisYearEarning(account).call();
      const earningTotal = await contract.methods.getTotalEarning(account).call();
      const referalsTotal = _totalRefferals.toString();
      const erningTodays = _todysEarning.toString() / 10 ** decimalNumber;
      const erningThisMonth = thisMonth.toString() / 10 ** decimalNumber;
      const erningThisYear = thisYear.toString() / 10 ** decimalNumber;
      const total_Erning = earningTotal.toString() / 10 ** decimalNumber;
      setTotalRefferals(referalsTotal);
      setTodaysEarning(erningTodays.toFixed(2));
      setThisMonthEarning(erningThisMonth.toFixed(2));
      setThisYearEarning(erningThisYear.toFixed(2));
      setTotalEarning(total_Erning.toFixed(2));
      console.log("Updating referrals sucsessfuly.", erningTodays, erningThisMonth, erningThisYear, total_Erning);
    } catch (error) {
      console.error("Error updating referrals", error);
    }
  }, [contract, account, totalEarning, totalRefferals]);

  const updateMilestoneReward = useCallback(async () => {
    console.log("Updating milestone reward...");
    if (!contract || !account) {
      console.log('Contract or account not available');
      return;
    }
    try {
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      const rewardsAmount = await contract.methods.getMilestonAmount(account).call();
      console.log("Raw milestone amount:", rewardsAmount);
      const rewardsAmountNumber = Number(rewardsAmount.toString());
      const amount = (rewardsAmountNumber / 10 ** decimalNumber).toFixed(2);
      console.log("Formatted milestone amount:", amount);
      setMilestonRewards(amount);
    } catch (error) {
      console.error("Error updating milestone amount", error);
      console.log("Contract address:", contract._address);
      console.log("Account:", account);
    }
  }, [contract, account, setMilestonRewards]);

  const checkApprovalStatus = useCallback(async () => {
    if (!usdtContract || !account || !strboonAddress) {
      console.log('usdtContract, account, or strboonAddress not available');
      return false;
    }
    try {
      const allowance = await usdtContract.methods.allowance(account, strboonAddress).call();
      return allowance > 0;
    } catch (error) {
      console.error('Error checking approval status:', error);
      return false;
    }
  }, [usdtContract, account, strboonAddress]);

  const connectWallet = async () => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const web3Instance = new Web3(window.ethereum);
      const accounts = await web3Instance.eth.getAccounts();
      const userAddress = accounts[0];
      setWeb3(web3Instance);
      setAccount(userAddress);
      setIsConnected(true);
      const usdtApproved = await checkApprovalStatus();
      await updateMilestoneReward();
      setApproved(usdtApproved);
      updateData();
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  };

  const updateData = useCallback(async () => {
    if (!stakingContract || !account) {
      console.log('StakingContract or account not available');
      return;
    }
    try {
      console.log(`Checking staking data for account: ${account}`);
      const tokenDecimal = await usdtContract.methods.decimals().call();
      const decimalNumber = Number(tokenDecimal);
      console.log('Decimal:', decimalNumber);
      // Get the staking time Unix timestamp in seconds
      const _stakingTime = await stakingContract.methods.stakingTime(account).call();
      const stakingTime = Number(_stakingTime.toString());
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - stakingTime;
      const daysStaked = Math.floor(elapsedTime / (60 * 60 * 24));
      const _depositedToken = await stakingContract.methods.depositedTokens(account).call();
      const getTaxNumbers = await dataLibraryContract.methods.getTaxNumbers(account).call();
      const getPendingRewards = await stakingContract.methods.getPendingReward(account).call();
      const getRewardData = await stakingContract.methods.getRewardData(account).call();
      const _dailyReward = await stakingContract.methods.getDailyReward(account).call();
      const _isSeller = await merchantContract.methods.getShopNonceBySeller(account).call();

      const isSeller = Number(_isSeller);
      if (isSeller > 0) {
        setIsSeller(true);
      }

      const dailyReward = Number(_dailyReward.toString());
      const getLavels = await contract.methods.getLevels(account).call();
      const usersLavels = Number(getLavels.toString());

      setUserLavels(usersLavels);

      const stakingStartDate = new Date(stakingTime * 1000);
      const today = new Date();
      const totalDays = Math.floor((today - stakingStartDate) / (1000 * 60 * 60 * 24));
      let erningData = [];

      let currentDate = new Date(stakingStartDate);
      console.log('Erning Data', currentDate);
      if (getRewardData.perDayReward.length > 0) {
        // User has claimed or reinvested before
        let dayCount = 0;
        for (let i = 0; i < getRewardData.perDayReward.length; i++) {
          const _rewardPerDay = Number(getRewardData.perDayReward[i].toString());
          const rewardPerDay = _rewardPerDay / (10 ** decimalNumber);
          const days = Number(getRewardData.totalDays[i].toString());

          for (let j = 0; j < days; j++) {
            erningData.push({
              time: currentDate.toISOString().split('T')[0], // Format: YYYY-MM-DD
              value: rewardPerDay
            });
            currentDate.setDate(currentDate.getDate() + 1);
            dayCount++;
          }
        }

        // Fill remaining days with current daily reward
        const remainingDays = totalDays - dayCount;
        const currentDailyReward = dailyReward / (10 ** decimalNumber); // Convert from wei to token units
        for (let i = 0; i < remainingDays; i++) {
          erningData.push({
            time: currentDate.toISOString().split('T')[0],
            value: currentDailyReward
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }
      } else {
        // User hasn't claimed or reinvested, use the same daily reward for all days
        const rewardPerDay = dailyReward / (10 ** decimalNumber); // Convert from wei to token units
        for (let i = 0; i < totalDays; i++) {
          erningData.push({
            time: currentDate.toISOString().split('T')[0],
            value: rewardPerDay
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }

      const erninngDatas = [
        { time: '2024-07-10', value: 2 },
        { time: '2024-07-11', value: 5 },
        { time: '2024-07-12', value: 8 },
        { time: '2024-07-13', value: 21 },
        { time: '2024-07-14', value: 8 },
        { time: '2024-07-15', value: 65 },
        { time: '2024-07-16', value: 28 },
        { time: '2024-07-17', value: 23 },
        { time: '2024-07-18', value: 42 },
        { time: '2024-07-19', value: 33 },
        { time: '2024-07-20', value: 24 },
        { time: '2024-07-21', value: 20 },
        { time: '2024-07-22', value: 21 },
        { time: '2024-07-23', value: 72 }
      ];

      // Set the erningData state
      if (erningData.length > 0) {
        setErninngData(erningData);
      } else {
        setErninngData(erninngDatas);
      }

      const pendingRewards = getPendingRewards.toString() / (10 ** decimalNumber);
      if (pendingRewards > 0) {
        setPendingReward(pendingRewards.toFixed(2));
      }

      let totalUsdtInvested = 0;
      let incomeFromReferral = 0;

      if (getTaxNumbers.length > 0) {
        for (let i = 0; i < getTaxNumbers.length; i++) {
          const taxData = await dataLibraryContract.methods.getTaxData(getTaxNumbers[i]).call();
          const amountStrings = taxData.amount.toString();
          const taxTypeStrings = taxData.types.toString();
          const amountInUsdt = (amountStrings / (10 ** decimalNumber));
          console.log("Amount Invest", amountInUsdt, taxTypeStrings);
          if (taxTypeStrings == 1) {
            totalUsdtInvested += amountInUsdt;
          }
          if (taxTypeStrings == 2) {
            incomeFromReferral += amountInUsdt;
          }
        }

        if (totalUsdtInvested > 0 && totalUsdtInvested < 1e3) {
          setTotalInvest(totalUsdtInvested.toFixed(2));
        } else if (totalUsdtInvested >= 1e3 && totalUsdtInvested < 1e6) {
          const usdtInKilo = totalUsdtInvested / 1e3;
          setTotalInvest(`${usdtInKilo} K`);
        } else if (totalUsdtInvested >= 1e6 && totalUsdtInvested < 1e9) {
          const usdtInKilo = totalUsdtInvested / 1e6;
          setTotalInvest(`${usdtInKilo} K`);
        } else if (totalUsdtInvested >= 1e9) {
          const usdtInKilo = totalUsdtInvested / 1e9;
          setTotalInvest(`${usdtInKilo} K`);
        }

        if (incomeFromReferral > 0 && incomeFromReferral < 1e3) {
          setReferalIncome(incomeFromReferral.toFixed(2));
        } else if (incomeFromReferral >= 1e3 && incomeFromReferral < 1e6) {
          const incomInKilos = (incomeFromReferral / 1e3).toFixed(2);
          setReferalIncome(incomInKilos);
        } else if (incomeFromReferral >= 1e6 && incomeFromReferral < 1e9) {
          const incomInKilos = (incomeFromReferral / 1e6).toFixed(2);
          setReferalIncome(incomInKilos);
        } else if (incomeFromReferral >= 1e9) {
          const incomInKilos = (incomeFromReferral / 1e9).toFixed(2);
          setReferalIncome(incomInKilos);
        }
        console.log('Amount invested', totalUsdtInvested, 'Income from referral:', incomeFromReferral, 'Total Usdt Invested', totalUsdtInvested);
      }

      const depositedTokenString = Number(_depositedToken.toString());
      const depositedToken = (depositedTokenString / (10 ** decimalNumber)).toFixed(2);
      console.log('Total token staked', depositedToken);

      if (depositedToken > 0 && depositedToken < 1e3) {
        setTotalTokenStaked(depositedToken);
      } else if (depositedToken >= 1e3 && depositedToken < 1e6) {
        const strbInKilo = (depositedToken / 1e3).toFixed(2);
        setTotalTokenStaked(`${strbInKilo} K`);
      } else if (depositedToken >= 1e6 && depositedToken < 1e9) {
        const strbInKilo = (depositedToken / 1e6).toFixed(2);
        setTotalTokenStaked(`${strbInKilo} K`);
      } else if (depositedToken >= 1e9) {
        const strbInKilo = (depositedToken / 1e9).toFixed(2);
        setTotalTokenStaked(`${strbInKilo} K`);
      }

      const daysToWithdraw = 300 - daysStaked;
      console.log('Remaining Days', daysToWithdraw);

      if (daysToWithdraw > 0) {
        setRemainingDays(daysToWithdraw);
      } else if (daysToWithdraw < 0 || daysToWithdraw === null || daysToWithdraw === 0) {
        setRemainingDays(0);
      }
    } catch (error) {
      console.error('Error to update data:', error);
    }
  }, [stakingContract, account]);

  useEffect(() => {
    if (isConnected && contract && account && usdtContract) {
      updateDecimals();
      updateMilestoneReward();
      updateReferral();
    }
  }, [isConnected, contract, account, usdtContract, updateMilestoneReward, updateReferral, updateDecimals]);

  const disconnectWallet = () => {
    setAccount('');
    setIsConnected(false);
    setApproved(false);
    setPendingReward(0);
    setTotalInvest(0);
    setTotalTokenStaked(0);
    setRemainingDays(0);
  };

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    const intervalId = setInterval(() => {
      updateData();
      updateReferral();
      updateMilestoneReward();
      checkApprovalStatus();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [updateData]);

  return (
    <div className="header">
      <div className="header-left">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {isConnected ? (
        <div className="header-right">
          <button className="logout-btn" onClick={disconnectWallet}><span class="glyphicon log-out glyphicon-log-out"> </span>
            Logout
          </button>
        </div>
      ) : (
        <div className="header-right">
          <button className="connect-btn" onClick={connectWallet}> <span className='glyphicon log-in glyphicon-log-in'></span>
            Connect Wallet
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;