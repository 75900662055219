import React, { useState, useEffect, useContext } from 'react';
import BuyNowModal from './BuyNowModal';
import { Country, State, City } from 'country-state-city';
import { WalletContext } from '../WalletContext';
import Select from 'react-select';
import '../styles/ProductListing.css';

const demoProducts = [
  {
    id: 1,
    name: "Smartphone X",
    description: "Latest model with advanced features",
    price: "999",
    categori: "Electronics",
    brandName: "TechCo",
    quantity: "50",
    countries: ["USA", "Canada", "UK"],
    states: ["California", "New York", "Ontario", "London"],
    pinCodes: ["90210", "10001", "M5V 2T6", "SW1A 1AA"],
    imageUrl: "https://a.media-amazon.com/images/I/617l83eY1rL._SX679_.jpg"
  },
  {
    id: 2,
    name: "Designer Handbag",
    description: "Luxury leather handbag",
    price: "599",
    categori: "Fashion",
    brandName: "LuxeBrand",
    quantity: "25",
    countries: ["France", "Italy", "Spain"],
    states: ["Île-de-France", "Lombardy", "Catalonia"],
    pinCodes: ["75001", "20121", "08001"],
    imageUrl: "https://a.media-amazon.com/images/I/81mlQ7YVBlL._SY695_.jpg"
  },
  {
    id: 3,
    name: "Organic Coffee Beans",
    description: "Fair trade, single-origin coffee",
    price: "15",
    categori: "Food & Beverage",
    brandName: "EcoBrew",
    quantity: "100",
    countries: ["Brazil", "Colombia", "Ethiopia"],
    states: ["Minas Gerais", "Antioquia", "Sidamo"],
    pinCodes: ["30130-000", "050001", "251"],
    imageUrl: "https://a.media-amazon.com/images/I/61B166wGk1L._SX679_.jpg"
  },
  {
    id: 4,
    name: "Designer Handbag",
    description: "Luxury leather handbag",
    price: "599",
    categori: "Fashion",
    brandName: "LuxeBrand",
    quantity: "25",
    countries: ["France", "Italy", "Spain"],
    states: ["Île-de-France", "Lombardy", "Catalonia"],
    pinCodes: ["75001", "20121", "08001"],
    imageUrl: "https://a.media-amazon.com/images/I/81mlQ7YVBlL._SY695_.jpg"
  }
];

const ProductListing = () => {
  const { isConnected, account, merchantContract } = useContext(WalletContext);
  const [products, setProducts] = useState(demoProducts);
  const [filteredProducts, setFilteredProducts] = useState(demoProducts);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [pinCode, setPinCode] = useState('');
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const countries = Country.getAllCountries();

  const handleBuyNowClick = (product) => {
    setSelectedProduct(product);
    setIsBuyModalOpen(true);
  };

  const getStatesOfCountry = (countryCode) => {
    return State.getStatesOfCountry(countryCode);
  };

  const getCitiesOfState = (countryCode, stateCode) => {
    return City.getCitiesOfState(countryCode, stateCode);
  };

  useEffect(() => {
    const newCityOptions = selectedStates.flatMap(stateCode =>
      selectedCountries.flatMap(countryCode =>
        City.getCitiesOfState(countryCode, stateCode).map(city => ({
          value: city.name,
          label: city.name
        }))
      )
    );
    setCityOptions(newCityOptions);
  }, [selectedCountries, selectedStates]);

  const handleCityChange = (selectedOptions) => {
    const cities = selectedOptions.map(option => option.value);
    setSelectedCities(cities);
    filterProducts(searchTerm, selectedCategory, selectedCountries, selectedStates, cities, pinCode);
  };

  useEffect(() => {
    const newStateOptions = selectedCountries.flatMap(countryCode =>
      State.getStatesOfCountry(countryCode).map(state => ({
        value: state.isoCode,
        label: state.name
      }))
    );
    setStateOptions(newStateOptions);
  }, [selectedCountries]);

  // useEffect(() => {
  //   if (isConnected || merchantContract) {
  //     fetchProducts();
  //   }
  //   const uniqueCategories = [...new Set(demoProducts.map(product => product.categori))];
  //   setCategories(uniqueCategories);
  // }, []);

  // const fetchProducts = async () => {
  //   if (!isConnected || !merchantContract) {
  //     console.log('Not connected with wallet.')
  //     return;
  //   }
  //   try {
  //     const productNonce = await merchantContract.methods.productNonce().call();
  //     const totalProducts = Number(productNonce);
  //     const fetchedProducts = [];
  //     const uniqueCategories = new Set();

  //     for (let i = 78692; i < totalProducts; i++) {
  //       const productDetails = await merchantContract.methods.getProductDetails(i).call();
  //       // Ensure the product has all required properties
  //       const processedProduct = {
  //         ...productDetails,
  //         countries: productDetails.countries || [],
  //         states: productDetails.states || [],
  //         pinCodes: productDetails.pinCodes || []
  //       };
  //       fetchedProducts.push(processedProduct);
  //       uniqueCategories.add(processedProduct.categori);
  //     }
  //     console.log('Product details', fetchedProducts, uniqueCategories);

  //     setProducts(fetchedProducts);
  //     setFilteredProducts(fetchedProducts);
  //     setCategories(Array.from(uniqueCategories));
  //   } catch (error) {
  //     console.error('Error fetching products:', error);
  //   }
  // };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    filterProducts(term, selectedCategory, selectedCountries, selectedStates, pinCode);
  };

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    filterProducts(searchTerm, category, selectedCountries, selectedStates, pinCode);
  };

  const handleCountryChange = (selectedOptions) => {
    const countryCodes = selectedOptions.map(option => option.value);
    setSelectedCountries(countryCodes);
    filterProducts(searchTerm, selectedCategory, countryCodes, selectedStates, pinCode);
  };

  const handleStateChange = (selectedOptions) => {
    const states = selectedOptions.map(option => option.value);
    setSelectedStates(states);
    filterProducts(searchTerm, selectedCategory, selectedCountries, states, pinCode);
  };

  const handlePinCodeChange = (event) => {
    const code = event.target.value;
    setPinCode(code);
    filterProducts(searchTerm, selectedCategory, selectedCountries, selectedStates, code);
  };

  const filterProducts = (term, category, countries, states, code) => {
    const filtered = products.filter((product) => {
      const matchesTerm = product.name.toLowerCase().includes(term) ||
        product.description.toLowerCase().includes(term);
      const matchesCategory = category === '' || product.categori === category;
      const matchesCountry = countries.length === 0 || countries.some(country => product.countries.includes(country));
      const matchesState = states.length === 0 || states.some(state => product.states.includes(state));
      const matchesPinCode = code === '' || product.pinCodes.includes(code);
      return matchesTerm && matchesCategory && matchesCountry && matchesState && matchesPinCode;
    });
    setFilteredProducts(filtered);
  };

  return (
    <div className="product-listing-container">
      <div className="search-filter-container">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="category-select"
        >
          <option value="">All Categories</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </select>
        <Select
          isMulti
          name="countries"
          options={Country.getAllCountries().map(country => ({
            value: country.isoCode,
            label: country.name
          }))}
          className="country-select"
          classNamePrefix="select"
          onChange={handleCountryChange}
          placeholder="Select Countries"
        />
        <Select
          isMulti
          name="states"
          options={stateOptions}
          className="state-select"
          classNamePrefix="select"
          onChange={handleStateChange}
          placeholder="Select States"
        />
        <Select
          isMulti
          name="cities"
          options={cityOptions}
          className="city-select"
          classNamePrefix="select"
          onChange={handleCityChange}
          placeholder="Select Cities"
        />

        <input
          type="text"
          placeholder="Enter Pin Code"
          value={pinCode}
          onChange={handlePinCodeChange}
          className="pin-code-input"
        />
      </div>
      <div className="product-grid">
        {filteredProducts.map((product, index) => (
          <div key={index} className="product-card">
            <div className="product-header">
              <h3 className="product-title">{product.name}</h3>
              <img
                src={product.imageUrl}
                alt={product.name}
                className="product-image"
              />
            </div>
            <div className="product-content">
              <p className="product-description">{product.description}</p>
              <p className="product-price">Price: ${product.price}</p>
              <p className="product-info">Category: {product.categori}</p>
              <p className="product-info">Brand: {product.brandName}</p>
              <p className="product-info">Available: {product.quantity}</p>
              <p className="product-info">Available in: {(product.countries || []).join(', ')}</p>
            </div>
            <div className="product-footer">
              <button onClick={() => handleBuyNowClick(product)} className="add-to-cart-button">Buy Now</button>
            </div>
          </div>
        ))}
      </div>
      {selectedProduct && (
        <BuyNowModal
          isOpen={isBuyModalOpen}
          onClose={() => setIsBuyModalOpen(false)}
          product={[selectedProduct]}
        />
      )}
    </div>
  );
};

export default ProductListing;