import React, { useState, useEffect, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/TransactionHistory.css';

const TransactionHistory = () => {
  const { web3, account, isConnected, dataLibraryContract, usdtDecimal } = useContext(WalletContext);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    const intervalId = setInterval(() => {
      fetchTransactions();
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [isConnected, dataLibraryContract, account]);

  const fetchTransactions = async () => {
    try {
      console.log('Fetching transactions...');
      const taxNumbers = await dataLibraryContract.methods.getTaxNumbers(account).call();
      console.log('Tax numbers:', taxNumbers);

      const fetchedTransactions = await Promise.all(taxNumbers.map(async (taxNum) => {
        console.log('Fetching data for tax number:', taxNum);
        const taxData = await dataLibraryContract.methods.getTaxData(taxNum).call();
        console.log('Tax data:', taxData);

        const purpose = taxData.types.toString() === '1' ? 'Investment' : 'Commission';
        const date = new Date(
          Number(taxData.year),
          Number(taxData.month) - 1,
          Number(taxData.day),
          Number(taxData.hour) - 1,
          Number(taxData.minute),
          Number(taxData.second)
        );
        console.log('Date:', Number(taxData.year), Number(taxData.month), Number(taxData.day), Number(taxData.hour), Number(taxData.minute), Number(taxData.second));
        const formattedDate = date.toUTCString();
        const amoutString = taxData.amount.toString();
        const amountWithDecimal = amoutString / (10 ** usdtDecimal);

        return {
          purpose,
          amount: amountWithDecimal.toFixed(2),
          date: formattedDate
        };
      }));

      console.log('Fetched transactions:', fetchedTransactions);
      setTransactions(fetchedTransactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  return (
    <div className="transaction-history">
      <div className="table-container">
        <div className="table-header">
          <div className="header-cell">Types</div>
          <div className="header-cell">Amount</div>
          <div className="header-cell">Date</div>
        </div>
        <div className="table-body">
          {transactions.length > 0 ? (
            transactions.map((tx, index) => (
              <div className="table-row" key={index}>
                <div className="cell" data-label="Purpose">{tx.purpose}</div>
                <div className="cell" data-label="Amount">$ {tx.amount}</div>
                <div className="cell" data-label="Date">{tx.date}</div>
              </div>
            ))
          ) : (
            <div className="table-row">
              <div className="cell" colSpan="4">No Data Found</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;