// App.js
import { React, useState } from 'react';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import TransactionHistory from './components/TransactionHistory';
import OrderPaymant from './components/OrderPaymant';
import VideoTutorials from './components/Video';
import AddProduct from './components/AddProductForm';
import ProductListing from './components/ProductListing';
import { WalletProvider } from './WalletContext';
import './App.css';

function App() {
  const [selectedItem, setSelectedItem] = useState('Home');

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <WalletProvider>
      <div className="app" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <Sidebar onItemClick={handleItemClick} />
          <div className="main-content" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Header />
            <div style={{ flex: 1 }}>
              {selectedItem === 'Home' && <ProductListing />}
              {selectedItem === 'Order & Payment' && <OrderPaymant />}
              {selectedItem === 'Add Product' && <AddProduct />}
              {selectedItem === 'History' && <TransactionHistory />}
              {selectedItem === 'Tutorials' && <VideoTutorials />}
            </div>
          </div>
        </div>
      </div>
    </WalletProvider>
  );
};

export default App;