import React, { useState, useContext, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/AddProductForm.css';

const AddProductForm = () => {
  const { account, merchantContract } = useContext(WalletContext);
  const [formData, setFormData] = useState({
    seller: account,
    nonce: 0,
    name: '',
    categori: '',
    brandName: '',
    description: '',
    urls: [],
    targetGender: '',
    ageRange: [],
    colors: [],
    size: [],
    newOrused: '',
    country: [],
    state: [],
    postCode: [],
    quantity: 0,
    price: 0,
    mrp: 0,
    isSellin: true
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [shopDetails, setShopDetails] = useState(null);

  useEffect(() => {
    fetchShopDetails();
  }, []);

  const fetchShopDetails = async () => {
    try {
      const shopNonce = await merchantContract.methods.getShopNonceBySeller(account).call();
      const details = await merchantContract.methods.getShopDetails(shopNonce).call();
      setShopDetails(details);
    } catch (err) {
      console.error('Error fetching shop details:', err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleArrayInputChange = (e, field) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const value = e.target.value.trim();
      if (value) {
        setFormData(prevState => ({
          ...prevState,
          [field]: [...prevState[field], value]
        }));
        e.target.value = '';
      }
    }
  };

  const handleRemoveArrayItem = (field, index) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: prevState[field].filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const _productNonce = await merchantContract.methods.productNonce().call();
      const productNonce =  Number(_productNonce);
      const updatedFormData = { ...formData, nonce: productNonce + 1, totalSold: 0, pendingDelivery: 0 };
      console.log('Add product', updatedFormData.length);
      
      await merchantContract.methods.addProduct(updatedFormData).send({ from: account });
      setSuccess('Product added successfully!');
      // Reset form or redirect
    } catch (err) {
      setError('Failed to add product: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!shopDetails) {
    return <div>Loading shop details...</div>;
  }

  if (!shopDetails.isSelling) {
    return <div>Your shop is not approved for selling. Please contact support.</div>;
  }

  return (
    <div className="form-container">
      <h2 className="heading">Add New Product</h2>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <form onSubmit={handleSubmit} className="form">
        <div className="formGroup">
          <label htmlFor="name" className="label">Product Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="input"
          />
        </div>

        <div className="formGroup">
          <label htmlFor="categori" className="label">Category</label>
          <select
            id="categori"
            name="categori"
            value={formData.categori}
            onChange={handleInputChange}
            required
            className="select"
          >
            <option value="">Select category</option>
            {shopDetails.categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        <div className="formGroup">
          <label htmlFor="brandName" className="label">Brand Name</label>
          <input
            type="text"
            id="brandName"
            name="brandName"
            value={formData.brandName}
            onChange={handleInputChange}
            required
            className="input"
          />
        </div>

        <div className="formGroup">
          <label htmlFor="description" className="label">Description</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            required
            className="textarea"
          />
        </div>

        <div className="formGroup">
          <label htmlFor="urls" className="label">Product Images (URLs)</label>
          <input
            type="url"
            id="urls"
            onKeyPress={(e) => e.key === 'Enter' && handleArrayInputChange(e, 'urls')}
            className="input"
          />
          <div className="arrayItems">
            {formData.urls.map((url, index) => (
              <div key={index} className="arrayItem">
                {url}
                <button type="button" onClick={() => handleRemoveArrayItem('urls', index)} className="removeButton">Remove</button>
              </div>
            ))}
          </div>
        </div>

        <div className="formGroup">
          <label htmlFor="targetGender" className="label">Target Gender</label>
          <select
            id="targetGender"
            name="targetGender"
            value={formData.targetGender}
            onChange={handleInputChange}
            required
            className="select"
          >
            <option value="">Select gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Unisex">Unisex</option>
          </select>
        </div>

        <div className="formGroup">
          <label htmlFor="ageRange" className="label">Age Range</label>
          <input
            type="text"
            id="ageRange"
            onKeyPress={(e) => e.key === 'Enter' && handleArrayInputChange(e, 'ageRange')}
            className="input"
            placeholder="Enter age range and press Enter"
          />
          <div className="arrayItems">
            {formData.ageRange.map((range, index) => (
              <div key={index} className="arrayItem">
                {range}
                <button type="button" onClick={() => handleRemoveArrayItem('ageRange', index)} className="removeButton">Remove</button>
              </div>
            ))}
          </div>
        </div>

        <div className="formGroup">
          <label htmlFor="colors" className="label">Colors</label>
          <input
            type="text"
            id="colors"
            onKeyPress={(e) => e.key === 'Enter' && handleArrayInputChange(e, 'colors')}
            className="input"
            placeholder="Enter color and press Enter"
          />
          <div className="arrayItems">
            {formData.colors.map((color, index) => (
              <div key={index} className="arrayItem">
                {color}
                <button type="button" onClick={() => handleRemoveArrayItem('colors', index)} className="removeButton">Remove</button>
              </div>
            ))}
          </div>
        </div>

        <div className="formGroup">
          <label htmlFor="size" className="label">Sizes</label>
          <input
            type="text"
            id="size"
            onKeyPress={(e) => e.key === 'Enter' && handleArrayInputChange(e, 'size')}
            className="input"
            placeholder="Enter size and press Enter"
          />
          <div className="arrayItems">
            {formData.size.map((s, index) => (
              <div key={index} className="arrayItem">
                {s}
                <button type="button" onClick={() => handleRemoveArrayItem('size', index)} className="removeButton">Remove</button>
              </div>
            ))}
          </div>
        </div>

        <div className="formGroup">
          <label htmlFor="newOrused" className="label">Condition</label>
          <select
            id="newOrused"
            name="newOrused"
            value={formData.newOrused}
            onChange={handleInputChange}
            required
            className="select"
          >
            <option value="">Select condition</option>
            <option value="New">New</option>
            <option value="Used">Used</option>
          </select>
        </div>

        <div className="formGroup">
          <label htmlFor="country" className="label">Countries</label>
          <input
            type="text"
            id="country"
            onKeyPress={(e) => handleArrayInputChange(e, 'country')}
            className="input"
            placeholder="Enter country and press Enter"
          />
          <div className="arrayItems">
            {formData.country.map((c, index) => (
              <div key={index} className="arrayItem">
                {c}
                <button type="button" onClick={() => handleRemoveArrayItem('country', index)} className="removeButton">Remove</button>
              </div>
            ))}
          </div>
        </div>

        <div className="formGroup">
          <label htmlFor="state" className="label">States</label>
          <input
            type="text"
            id="state"
            onKeyPress={(e) => handleArrayInputChange(e, 'state')}
            className="input"
            placeholder="Enter state and press Enter"
          />
          <div className="arrayItems">
            {formData.state.map((s, index) => (
              <div key={index} className="arrayItem">
                {s}
                <button type="button" onClick={() => handleRemoveArrayItem('state', index)} className="removeButton">Remove</button>
              </div>
            ))}
          </div>
        </div>

<div className="formGroup">
  <label htmlFor="postCode" className="label">Post Codes</label>
  <input
    type="number"
    id="postCode"
    onKeyPress={(e) => e.key === 'Enter' && handleArrayInputChange(e, 'postCode')}
    className="input"
    placeholder="Enter post code and press Enter"
  />
  <div className="arrayItems">
    {formData.postCode.map((code, index) => (
      <div key={index} className="arrayItem">
        {code}
        <button type="button" onClick={() => handleRemoveArrayItem('postCode', index)} className="removeButton">Remove</button>
      </div>
    ))}
  </div>
</div>

<div className="formGroup">
  <label htmlFor="quantity" className="label">Quantity</label>
  <input
    type="number"
    id="quantity"
    name="quantity"
    value={formData.quantity}
    onChange={handleInputChange}
    required
    className="input"
  />
</div>

<div className="formGroup">
  <label htmlFor="price" className="label">Price</label>
  <input
    type="number"
    id="price"
    name="price"
    value={formData.price}
    onChange={handleInputChange}
    required
    className="input"
  />
</div>

<div className="formGroup">
  <label htmlFor="mrp" className="label">MRP</label>
  <input
    type="number"
    id="mrp"
    name="mrp"
    value={formData.mrp}
    onChange={handleInputChange}
    required
    className="input"
  />
</div>

<button type="submit" className="button" disabled={loading}>
  {loading ? 'Adding Product...' : 'Add Product'}
</button>
</form>
</div>
);
};

export default AddProductForm;