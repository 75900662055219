import React, { useState, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/BuyNowModal.css';

const BuyNowModal = ({ isOpen, onClose, product }) => {
  const { isConnected, account, merchantContract, usdtDecimal } = useContext(WalletContext);
  const [quantity, setQuantity] = useState(1);
  const [color, setColor] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  const handleSubmit = async (productData, price) => {
    if (quantity <= 0 || !color) {
      setAlertMessage('Please enter valid quantity and color!');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage('');
      }, 2000);
      return;
    }
    if (!isConnected || !merchantContract) {
      console.log('Wallet not connected');
      return;
    }

    try {
      const taxHas = await merchantContract.methods.buy(productData, 0, 0, 0, 0, price * (10 ** usdtDecimal)).send({ from: account });
      console.log('Buy successful', taxHas.transactionHash);
      setAlertMessage('Order placed successfully!');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage('');
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error placing order: ', error);
      setAlertMessage('Failed to place order!');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage('');
      }, 2000);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2><span className="close-btn" onClick={onClose}>X</span></h2>
        {product.map((item, index) => (
          <div key={index} className="product-card">
            <div className="product-header">
              <h3 className="product-title">{item.name}</h3>
              <img
                src={item.imageUrl}
                alt={item.name}
                className="product-image"
              />
            </div>
            <div className="product-content">
              <p className="product-description">{item.description}</p>
              <p className="product-price">Price: ${item.price}</p>
              <p className="product-info">Category: {item.categori}</p>
              <p className="product-info">Brand: {item.brandName}</p>
              <p className="product-info">Available: {item.quantity}</p>
              <p className="product-info">Available in: {item.countries.join(', ')}</p>
            </div>
            <div className='quantity-color'>
            <div className="input-group">
              <label htmlFor="quantity">Quantity:</label>
              <input
                id="quantity"
                type="number"
                min="1"
                value={quantity}
                onChange={handleQuantityChange}
              />
            </div>

            <div className="input-group">
              <label htmlFor="color">Color:</label>
              <input
                id="color"
                type="text"
                placeholder="Enter color"
                value={color}
                onChange={handleColorChange}
              />
            </div>
            </div>
            <div className="product-footer">
              <button onClick={() => handleSubmit(item, item.price)} className="buy-button">Buy Now</button>
            </div>
          </div>
        ))}

        {showAlert && <div className="custom-alert">{alertMessage}</div>}
      </div>
    </div>
  );
};

export default BuyNowModal;